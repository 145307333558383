import useAuthorizedApi from "../authorized-api";
import {Instance} from "../../models/instance";
import {useState} from "react";

const apiUrl = window._env_.REACT_APP_API_URL;

export default function useInstancesApi() {
    const {get, post, put, doDelete} = useAuthorizedApi();
    const [instanceApiError, setInstanceApiError] = useState(null);

    const tryApiCall = async (func) => {
        try {
            return await func();
        } catch (err) {
            console.error(err);
            if (typeof err === "string" && err.toLowerCase().indexOf("already exist") >= 0) {
                setInstanceApiError(err);
            } else if (typeof err === "string" && err.toLowerCase().indexOf("mimetype") >= 0) {
                setInstanceApiError(err);
            } else {
                setInstanceApiError("The API is currently unavailable, please contact the administrator");
            }
            return null;
        }
    }

    const fetchInstances = async () => tryApiCall(async () => {
        const response = await get(`${apiUrl}/api/instances`);
        return response?.data.map(inst => new Instance(inst));
    });

    const updateInstance = async (instance) => tryApiCall(async () => {
        const response = await put(`${apiUrl}/api/instances/${instance.instanceId}`, instance);
        return new Instance(response.data);
    });

    const deleteInstance = async (instanceId) => tryApiCall(async () => {
        const response = await doDelete(`${apiUrl}/api/instances/${instanceId}`);
        return new Instance(response.data);
    });

    const createInstance = (instance) => tryApiCall(async () => {
        const response = await post(`${apiUrl}/api/instances`, instance);
        return new Instance(response.data);
    });

    const uploadCoverPhoto = (instanceId, formData) => tryApiCall(async () => {
        const response = await post(
            `${apiUrl}/api/instances/${instanceId}/upload-cover-photo`,
            formData
        );
        return new Instance(response.data);
    });

    return {
        fetchInstances,
        updateInstance,
        deleteInstance,
        createInstance,
        uploadCoverPhoto,
        instanceApiError,
    };
}