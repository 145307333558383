import useAuthorizedApi from "./authorized-api";
import {Album} from "../models/album";

const apiUrl = window._env_.REACT_APP_API_URL;

export default function useAlbumsApi() {
    const {get, post, patch, doDelete} = useAuthorizedApi();

    return {
        fetchAlbums,
        postAlbum,
        patchAlbum,
        deleteAlbum,
        moveAlbumDown,
        moveAlbumUp,
        patchPhoto,
        uploadPhotos,
        deletePhoto,
        movePhotoDown,
        movePhotoUp,
    };

    /**
     * @return {Promise<Album[]>}
     */
    async function fetchAlbums(instance) {
        const response = await get(`${apiUrl}/api/instances/${instance.instanceId}/albums`);
        return response.data.map(album => new Album(album));
    }

    /**
     * @param {Instance} instance
     * @param {Album} album
     * @return {Promise<Album>}
     */
    async function patchAlbum(instance, album) {
        const patches = [];
        if (album.name) {
            patches.push({op: "replace", path: "/name", value: album.name})
        }
        if (album.description) {
            patches.push({op: "replace", path: "/description", value: album.description})
        }
        if (album.sort) {
            patches.push({op: "replace", path: "/sort", value: album.sort})
        }
        if (patches.length === 0) {
            return null;
        }
        const response = await patch(
            `${apiUrl}/api/instances/${instance.instanceId}/albums/${album.albumId}`,
            patches
        );
        return new Album(response.data);
    }

    /**
     * @param {Instance} instance
     * @param {Album} album
     * @return {Promise<Album>}
     */
    async function postAlbum(instance, album) {
        const response = await post(
            `${apiUrl}/api/instances/${instance.instanceId}/albums`, {
                name: album.name,
                description: album.description,
                sort: album.sort
            }
        );
        return new Album(response.data);
    }

    async function deleteAlbum(instance, albumId) {
        const response = await doDelete(`${apiUrl}/api/instances/${instance.instanceId}/albums/${albumId}`);
        return response.data.map(a => new Album(a));
    }

    async function moveAlbumDown(instance, albumId) {
        const response = await post(`${apiUrl}/api/instances/${instance.instanceId}/albums/${albumId}/move-down`);
        return response.data.map(a => new Album(a));
    }

    async function moveAlbumUp(instance, albumId) {
        const response = await post(`${apiUrl}/api/instances/${instance.instanceId}/albums/${albumId}/move-up`);
        return response.data.map(a => new Album(a));
    }

    /**
     * @param {Instance} instance
     * @param {string} albumId
     * @param {Photo} photo
     * @return {Promise<Album>}
     */
    async function patchPhoto(instance, albumId, photo) {
        const patches = [];
        if (photo.name) {
            patches.push({op: "replace", path: "/name", value: photo.name})
        }
        if (photo.description) {
            patches.push({op: "replace", path: "/description", value: photo.description})
        }
        if (patches.length === 0) {
            return null;
        }
        const response = await patch(
            `${apiUrl}/api/instances/${instance.instanceId}/albums/${albumId}/photos/${photo.photoId}`,
            patches
        );
        return new Album(response.data);
    }

    async function uploadPhotos(instance, albumId, formData) {
        const response = await post(
            `${apiUrl}/api/instances/${instance.instanceId}/albums/${albumId}/upload-photos`,
            formData
        );
        return new Album(response.data);
    }

    async function deletePhoto(instance, albumId, photoId) {
        const response = await doDelete(`${apiUrl}/api/instances/${instance.instanceId}/albums/${albumId}/photos/${photoId}`);
        return new Album(response.data);
    }

    async function movePhotoDown(instance, albumId, photoId) {
        const response = await post(`${apiUrl}/api/instances/${instance.instanceId}/albums/${albumId}/photos/${photoId}/move-down`);
        return new Album(response.data);
    }

    async function movePhotoUp(instance, albumId, photoId) {
        const response = await post(`${apiUrl}/api/instances/${instance.instanceId}/albums/${albumId}/photos/${photoId}/move-up`);
        return new Album(response.data);
    }
}