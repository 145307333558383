import {useAuth} from "./authentication/use-auth";

export default function useAuthorizedApi() {
    const {userData, signOut} = useAuth();
    return {
        get: request('GET'),
        post: request('POST'),
        put: request('PUT'),
        patch: request('PATCH'),
        doDelete: request('DELETE')
    };

    /**
     * @param {string} method
     * @return {function(*, *): Promise<*>}
     */
    function request(method) {
        /**
         * @param {string} url
         * @param {?any} [body]
         */
        return async (url, body) => {
            /** @type {RequestInit} **/
            const requestOptions = {
                method,
                headers: authHeader(url)
            };
            if (body) {
                if (body instanceof FormData) {
                    requestOptions.body = body;
                } else {
                    requestOptions.headers['Content-Type'] = 'application/json';
                    requestOptions.body = JSON.stringify(body);
                }
            }
            return handleResponse(await fetch(url, requestOptions));
        }
    }

    function authHeader(url) {
        // return auth header with jwt if user is logged in and request is to the api url
        const token = userData?.access_token;
        const isLoggedIn = !!token;
        const isApiUrl = url.startsWith(window._env_.REACT_APP_API_URL);
        if (isLoggedIn && isApiUrl) {
            return {Authorization: `Bearer ${token}`};
        } else {
            return {};
        }
    }

    async function handleResponse(response) {
        const text = await response.text()

        const data = text && JSON.parse(text);

        if (!response.ok) {
            if ([401, 403].includes(response.status) && userData?.access_token) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                localStorage.removeItem('user');
                await signOut();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    }
}