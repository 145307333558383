import React, {useEffect, useState} from "react";
import useInstancesApi from "./instances-api";
import {useAuth} from "../authentication/use-auth";
import {Instance} from "../../models/instance";

export const InstancesContext = React.createContext(
    undefined
);

export const InstancesProvider = ({children}) => {
    // const {pathname} = useLocation();
    const {userData} = useAuth();
    const {fetchInstances, createInstance, deleteInstance, updateInstance, uploadCoverPhoto, instanceApiError} = useInstancesApi();

    const [isLoading, setIsLoading] = useState(true);
    const [instances, setInstances] = useState(/** @type {Instance[]} **/undefined);
    const [selectedInstance, setSelectedInstance] = useState(null);

    useEffect(() => {
        void (async () => {
            if (userData && fetchInstances) {
                if (instances === undefined) {
                    setIsLoading(true);
                    const instances = await fetchInstances();
                    setInstances(instances);
                    setIsLoading(false);
                }
            }
        })();
    }, [userData, instances, fetchInstances]);

    const handleCreateInstance = async (instance) => {
        const newInstance = await createInstance(instance);
        setInstances([...instances, newInstance]);
    }
    const handleUpdateInstance = async (instance) => {
        const updatedInstance = await updateInstance(instance);
        const idx = instances.findIndex(i => i.instanceId === updatedInstance.instanceId);
        instances[idx] = updatedInstance;
        setInstances([...instances]);
        return updatedInstance;
    }
    const handleDeleteInstance = async (instanceId) => {
        await deleteInstance(instanceId);
        const idxToRemove = instances.findIndex(i => i.instanceId === instanceId);
        instances.splice(idxToRemove, 1)
        setInstances([...instances]);
    }
    const handleUpdateCoverPhoto = async (instanceId, formData) => {
        const updatedInstance = await uploadCoverPhoto(instanceId, formData);
        const idx = instances.findIndex(i => i.instanceId === updatedInstance.instanceId);
        instances[idx] = updatedInstance;
        setInstances(instances.map(i => new Instance(i)));
        return updatedInstance;
    }

    const value = {
        isLoading,
        instances,
        selectedInstance,
        setSelectedInstance,
        createInstance: handleCreateInstance,
        deleteInstance: handleDeleteInstance,
        updateInstance: handleUpdateInstance,
        uploadCoverPhoto: handleUpdateCoverPhoto,
        instanceApiError,
    };

    return <InstancesContext.Provider value={value}>{children}</InstancesContext.Provider>;
};