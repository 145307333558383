import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
    createBrowserRouter,
    createRoutesFromElements, Link,
    Navigate, Outlet,
    Route,
    RouterProvider,
} from "react-router-dom";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Keycloak from "./services/authentication/keycloak";
import InstanceAlbumsPage from "./pages/instance-albums-page";
import AlbumPage from "./pages/album-page";
import {InstancesPage} from "./pages/instances-page";
import {NoAccessPage} from "./pages/no-access-page";
import {InstancesProvider} from "./services/instances/instances-context";
import {AdminLayout} from "./layout";
import {Home} from "@mui/icons-material";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<AdminLayout/>} handle={{
            crumb: () => <Link to="/"><Home /></Link>
        }}>
            <Route index element={<InstancesPage/>}/>
            <Route path="/no-access" element={<NoAccessPage/>}/>
            <Route path="/:instanceId" handle={{
                crumb: (routeData) => <span>{routeData?.params?.instanceId}</span>
            }}>
                <Route index path="" element={<Navigate to="albums"/>} />
                <Route path="albums/*" element={<Outlet />} handle={{
                    crumb: (routeData) => <Link to={`/${routeData?.params?.instanceId}/albums`}>albums</Link>
                }}>
                    <Route index element={<InstanceAlbumsPage/>} />
                    <Route path=":albumId" element={<AlbumPage />} handle={{
                        crumb: (routeData) => <Link to={`/${routeData?.params?.instanceId}/albums/${routeData.params.albumId}`}>{routeData.params.albumId}</Link>
                    }}/>
                </Route>
                <Route path="signin-keycloak" element={<Navigate to="/"/>}/>
            </Route>
            <Route path="*" element={<Navigate to={"/no-access"}/>}/>
        </Route>
    )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Keycloak>
            <InstancesProvider>
                <RouterProvider router={router} />
            </InstancesProvider>
        </Keycloak>
    </React.StrictMode>
);