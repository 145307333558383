import * as React from "react";
import Header from "./components/header";
import {Outlet, useLocation, useNavigate, useNavigation} from "react-router-dom";
import {useEffect, useLayoutEffect} from "react";

import "./admin.scss";
import Loader from "./components/Loader";

import {createTheme} from '@mui/material/styles';
import {ThemeProvider} from "@mui/material";
import {useAuth} from "./services/authentication/use-auth";
import {useInstances} from "./services/instances/use-instances";
import {Breadcrumb} from "./components/breadcrumb";

const theme = createTheme({
    palette: {
        primary: {
            main: '#de5200'
        }
    },
});


export function AdminLayout() {
    const navigation = useNavigation();
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const {isLoading, userData, userManager, signIn} = useAuth();
    const {isLoading: instancesIsLoading, setSelectedInstance, selectedInstance, instances} = useInstances();

    useEffect(() => {
        async function handleLogin() {
            try {
                await userManager.signinSilentCallback();
            } catch (err) {
            }
        }

        async function silentLogin() {
            try {
                await userManager.signinSilent();
            } catch (err) {
                await signIn();
            }
        }

        if (!userData && !isLoading) {
            if (window.location.href.indexOf("/signin-keycloak") >= 0) {
                handleLogin();
            } else if (window.location.href.indexOf("/signin-keycloak") >= 0) {
                navigate("/");
            } else {
                silentLogin();
            }
        }

        if (instances && !selectedInstance && setSelectedInstance) {
            const possibleSelectedInstance = pathname.split('/')[1];
            if (possibleSelectedInstance) {
                const selectedInstance = instances?.find(inst => inst.instanceId === possibleSelectedInstance);
                if (selectedInstance) {
                    console.log("We found an instance", selectedInstance);
                    setSelectedInstance(selectedInstance);
                } else {
                    console.log("We could not detect an instance", possibleSelectedInstance);
                    navigate("/");
                }
            } else {
                setSelectedInstance(null);
            }
        }
    }, [userData, isLoading, userManager, navigate, signIn, setSelectedInstance, selectedInstance, pathname, instances]);

    return <ThemeProvider theme={theme}>
        <ScrollToTop>
            <Header/>
            <div id="admin-page">
                <main id="page">
                    <Breadcrumb />
                    {userData ? <>
                            {(isLoading || instancesIsLoading || navigation.state === 'loading') && <Loader/>}
                            {!isLoading && !instancesIsLoading && <Outlet/>}
                        </>
                        : <Loader/>
                    }
                </main>
            </div>
        </ScrollToTop>
    </ThemeProvider>
}


function ScrollToTop({children}) {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
}