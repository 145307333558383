import React, {
    useState,
    useEffect,
    useRef,
    useMemo,
    useCallback,
} from 'react';
import {
    UserManager,
} from 'oidc-client-ts';

export const AuthContext = React.createContext(
    undefined
);

/**
 * @private
 * @hidden
 * @param {Location} location
 */
export const hasCodeInUrl = (location) => {
    const searchParams = new URLSearchParams(location.search);
    const hashParams = new URLSearchParams(location.hash.replace('#', '?'));

    return (
        searchParams.has('code') ??
        searchParams.has('id_token') ??
        searchParams.has('session_state') ??
        hashParams.has('code') ??
        hashParams.has('id_token') ??
        hashParams.has('session_state')
    );
};

/**
 * @private
 * @hidden
 * @param props
 */
export const initUserManager = (props) => {
    if (props.userManager) {
        return props.userManager;
    }

    const {
        authority,
        clientId,
        clientSecret,
        redirectUri,
        silentRedirectUri,
        postLogoutRedirectUri,
        responseType,
        scope,
        automaticSilentRenew,
        loadUserInfo,
        popupWindowFeatures,
        popupRedirectUri,
        popupWindowTarget,
        extraQueryParams,
        metadata,
    } = props;
    return new UserManager({
        authority: authority ?? '',
        client_id: clientId ?? '',
        client_secret: clientSecret,
        redirect_uri: redirectUri ?? '',
        silent_redirect_uri: silentRedirectUri ?? redirectUri,
        post_logout_redirect_uri: postLogoutRedirectUri ?? redirectUri,
        response_type: responseType ?? 'code',
        scope: scope ?? 'authentication',
        loadUserInfo: loadUserInfo ?? true,
        popupWindowFeatures: popupWindowFeatures,
        popup_redirect_uri: popupRedirectUri,
        popupWindowTarget: popupWindowTarget,
        automaticSilentRenew,
        extraQueryParams,
        metadata: metadata,
    });
};

/**
 *
 * @param children
 * @param autoSignIn
 * @param autoSignInArgs
 * @param autoSignOut
 * @param autoSignOutArgs
 * @param onBeforeSignIn
 * @param onSignIn
 * @param onSignOut
 * @param location
 * @param onSignInError
 * @param props AuthProviderProps
 */
export const AuthProvider = ({
   children,
   autoSignIn = true,
   autoSignInArgs,
   autoSignOut = true,
   autoSignOutArgs,
   onBeforeSignIn,
   onSignIn,
   onSignOut,
   location = window.location,
   onSignInError,
   ...props
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const [userManager] = useState(() => initUserManager(props));
    const isMountedRef = useRef(false);

    const signOutHooks = useCallback(async ()=> {
        setUserData(null);
        if (onSignOut) {
            await onSignOut();
        }
    }, [onSignOut]);
    const signInPopupHooks = useCallback(async ()=> {
        const userFromPopup = await userManager.signinPopup();
        setUserData(userFromPopup);
        if (onSignIn) {
            await onSignIn(userFromPopup);
        }
        await userManager.signinPopupCallback();
    }, [userManager, onSignIn]);

    /**
     * Handles user auth flow on initial render.
     */
    useEffect(() => {
        let isMounted = true;
        isMountedRef.current = true;
        setIsLoading(true);
        void (async () => {
            if (!userManager) {
                return;
            }

            const user = await userManager.getUser();

            // isMountedRef cannot be used here as its value is updated by next useEffect.
            // We intend to keep context of current useEffect.
            if (isMounted && (!user || user.expired)) {
                // If the user is returning back from the OIDC provider, get and set the user data.
                if (hasCodeInUrl(location)) {
                    try {
                        const user = await userManager.signinCallback();
                        if (user) {
                            setUserData(user);
                            if (onSignIn) {
                                await onSignIn(user);
                            }
                        }
                    } catch (error) {
                        if (onSignInError) {
                            onSignInError(error);
                        } else {
                            throw error;
                        }
                    }
                }
                // If autoSignIn is enabled, redirect to the OIDC provider.
                else if (autoSignIn) {
                    const state = onBeforeSignIn ? onBeforeSignIn() : undefined;
                    await userManager.signinRedirect({ ...autoSignInArgs, state });
                }
            }
            // Otherwise if the user is already signed in, set the user data.
            else if (isMountedRef.current) {
                setUserData(user);
            }
            setIsLoading(false);
        })();
        return () => {
            isMounted = false;
            isMountedRef.current = false;
        };
    }, [
        location,
        userManager,
        autoSignIn,
        onBeforeSignIn,
        onSignIn,
        onSignInError,
        autoSignInArgs,
    ]);

    /**
     * Registers UserManager event callbacks for handling changes to user state due to automaticSilentRenew, session expiry, etc.
     */
    useEffect(() => {
        const updateUserData = (user) => {
            if (isMountedRef.current) {
                setUserData(user);
            }
        };
        const onSilentRenewError =
            async () => {
            if (autoSignOut) {
                await signOutHooks();
                await userManager.signoutRedirect(autoSignOutArgs);
            }
        };
        userManager.events.addUserLoaded(updateUserData);
        userManager.events.addSilentRenewError(onSilentRenewError);
        return () => {
            userManager.events.removeUserLoaded(updateUserData);
            userManager.events.removeSilentRenewError(onSilentRenewError);
        };
    }, [userManager, autoSignOut, signOutHooks, autoSignOutArgs]);

    const value = useMemo(() => {
        return {
            signIn: async (args) => {
            await userManager.signinRedirect(args);
        },
        signInPopup: async () => {
            await signInPopupHooks();
        },
        signOut: async ()=> {
            await userManager.removeUser();
            await signOutHooks();
        },
        signOutRedirect: async (args) => {
            await userManager.signoutRedirect(args);
            await signOutHooks();
        },
        userManager,
            userData,
            isLoading,
    };
    }, [userManager, isLoading, userData, signInPopupHooks, signOutHooks]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};