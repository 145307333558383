import {useContext} from "react";
import {InstancesContext} from "./instances-context";

/**
 * @return {InstancesContext}
 */
export const useInstances = () => {
    const context = useContext(InstancesContext);

    if (!context) {
        throw new Error(
            'Instances context is undefined, please verify you are calling useInstances() as child of a <InstancesProvider> component.',
        );
    }

    return context;
};