import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {AddAPhoto, ArrowCircleLeftOutlined, MoveDown, MoveUp} from "@mui/icons-material";
import {useRef, useState} from "react";
import useAlbumsApi from "../services/albums-api";
import {Button, IconButton, Input, TextField} from "@mui/material";
import {Photo} from "../models/photo";
import DragAndDrop from "../components/drag-and-drop/drag-and-drop";
import {Album} from "../models/album";
import Loader from "../components/Loader";

import "./album-page.scss";
import {useInstances} from "../services/instances/use-instances";

function AlbumPage() {
    const {state} = useLocation();
    const {album} = state; // Read values passed on state
    const {selectedInstance} = useInstances();

    const navigate = useNavigate();

    const {
        postAlbum,
        patchAlbum,
        patchPhoto,
        uploadPhotos,
        deletePhoto,
        movePhotoDown,
        movePhotoUp
    } = useAlbumsApi();

    const [name, setName] = useState(album.name || '');
    const [description, setDescription] = useState(album.description || '');
    const [sort] = useState(album.sort || "auto" );

    const [photosToUpdate, setPhotosToUpdate] = useState(album.photos.map(x => new Photo(x)));
    const [uploadApiError, setUploadApiError] = useState(null);
    const [apiError, setApiError] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const fileInputRef = useRef();

    const handleSaveEdit = async (e) => {
        try {
            if (hasChanges()) {
                setApiError(null);
                let albumToUpdate = new Album({
                    ...album,
                    name,
                    description
                });
                let updatedAlbum;
                if (album.albumId) {
                    updatedAlbum = await patchAlbum(selectedInstance, albumToUpdate);
                } else {
                    updatedAlbum = await postAlbum(selectedInstance, albumToUpdate);
                }
                navigate(`/${selectedInstance.instanceId}/albums/${updatedAlbum.albumId}`, {state: {album: updatedAlbum}});
            }
        } catch (err) {
            if (typeof err === "string" && err.toLowerCase().indexOf("duplicate") >= 0) {
                setApiError("Name already in use");
            } else {
                console.error(err);
                setApiError("An error occurred trying to save the album, contact the system administrator");
            }
        }
    }

    const handleSavePhotoEdit = async (idx) => {
        try {
            setApiError(null);
            if (photoHasChanges(idx)) {
                const updatedAlbum = await patchPhoto(
                    selectedInstance,
                    album.albumId,
                    photosToUpdate[idx]
                );
                navigate(`/${selectedInstance.instanceId}/albums/${updatedAlbum.albumId}`, {state: {album: updatedAlbum}});
            }
        } catch (err) {
            if (typeof err === "string" && err.toLowerCase().indexOf("duplicate") >= 0) {
                setApiError("Name already in use");
            } else {
                console.error(err);
                setApiError("An error occurred trying to save the photo, contact the system administrator");
            }
        }
    }

    const handleMovePhotoDown = async (idx) => {
        try {
            setApiError(null);
            const updatedAlbum = await movePhotoDown(
                selectedInstance,
                album.albumId,
                photosToUpdate[idx].photoId
            );
            const orgPhoto = photosToUpdate[idx];
            photosToUpdate[idx] = photosToUpdate[idx + 1];
            photosToUpdate[idx + 1] = orgPhoto;
            setPhotosToUpdate([...photosToUpdate]);
            navigate(`/${selectedInstance.instanceId}/albums/${updatedAlbum.albumId}`, {state: {album: updatedAlbum}});
        } catch (err) {
            console.error(err);
            setApiError("An error occurred trying to save the photo, contact the system administrator");
        }
    }

    const handleMovePhotoUp = async (idx) => {
        try {
            setApiError(null);
            const updatedAlbum = await movePhotoUp(
                selectedInstance,
                album.albumId,
                photosToUpdate[idx].photoId
            );
            const orgPhoto = photosToUpdate[idx];
            photosToUpdate[idx] = photosToUpdate[idx - 1];
            photosToUpdate[idx - 1] = orgPhoto;
            setPhotosToUpdate([...photosToUpdate]);
            navigate(`/${selectedInstance.instanceId}/albums/${updatedAlbum.albumId}`, {state: {album: updatedAlbum}});
        } catch (err) {
            console.error(err);
            setApiError("An error occurred trying to save the photo, contact the system administrator");
        }
    }

    const handleDeletePhoto = async (e, photoId) => {
        try {
            setApiError(null);
            const updatedAlbum = await deletePhoto(selectedInstance, album.albumId, photoId);
            setPhotosToUpdate(updatedAlbum.photos);
            navigate(`/${selectedInstance.instanceId}/albums/${updatedAlbum.albumId}`, {state: {album: updatedAlbum}});
        } catch (err) {
            setApiError("An error occurred trying to save the photo, contact the system administrator");
        }
    }

    const handlePhotoNameChange = (photoIndex, e) => {
        photosToUpdate[photoIndex].name = e.target.value;
        setPhotosToUpdate([...photosToUpdate]);
    }

    const handlePhotoDescriptionChange = (photoIndex, e) => {
        photosToUpdate[photoIndex].description = e.target.value;
        setPhotosToUpdate([...photosToUpdate]);
    }

    const hasChanges = () => album.sort !== sort || album.name !== name || album.description !== description;

    const photoHasChanges = (idx) => {
        return (photosToUpdate[idx].name || "") !== (album.photos[idx].name || "") ||
            (photosToUpdate[idx].description || "") !== (album.photos[idx].description || "");
    }

    const handleNavigateToOverview = (e) => {
        navigate(`/${selectedInstance.instanceId}`);
    }

    const handleDrop = async (files) => {
        await handleFiles(files)
    }

    const handleFiles = async (files) => {
        try {
            setUploadApiError(null);
            setUploadSuccess(null);
            setIsUploading(true);
            const arrFiles = [...files];
            const formData = new FormData();
            for (let file of arrFiles) {
                formData.append('photos', file);
            }
            const updatedAlbum = await uploadPhotos(selectedInstance, album.albumId, formData);
            setPhotosToUpdate(updatedAlbum.photos);
            setUploadSuccess(true);
            navigate(`/${selectedInstance.instanceId}/albums/${updatedAlbum.albumId}`, {state: {album: updatedAlbum}});
        } catch (err) {
            if (typeof err === "string" && err.toLowerCase().indexOf("already exist") >= 0) {
                setUploadApiError(err);
            } else if (typeof err === "string" && err.toLowerCase().indexOf("mimetype") >= 0) {
                setUploadApiError(err);
            } else {
                console.error(err);
                setUploadApiError("An error occurred trying to upload your photo's, contact the system administrator");
            }
        } finally {
            setIsUploading(false);
        }
    }

    return selectedInstance && <div id="admin-page-manage-albums">
        <div id="page-title">
            <div id="left-part">
                <IconButton onClick={handleNavigateToOverview} color="primary">
                    <ArrowCircleLeftOutlined id="navigate-back"/>
                </IconButton>
                <div id="edit-title" className="input-element">
                    <Input onKeyUp={(e) => e.key === "Enter" && handleSaveEdit(e)}
                           fullWidth={true}
                           type="text"
                           onChange={(e) => setName(e.target.value)}
                           placeholder="Name..."
                           tabIndex={1}
                           value={name}
                    />
                </div>
                <div className="photos-count">({album.photos.length} photos)</div>
            </div>
        </div>
        {apiError && <div className="error-box">{apiError}</div>}
        {album && <div>
            <div id="album-description">
                <div className="input-element">
                    <TextField multiline={true} fullWidth={true} rows={8}
                               onChange={(e) => setDescription(e.target.value)}
                               placeholder="Description..."
                               tabIndex={2}
                               value={description}
                    />
                </div>
            </div>
            <div className="save-button">
                <Button id="page-save" variant="contained" color="info" disabled={!hasChanges()}
                        onClick={handleSaveEdit}>
                    Save
                </Button>
            </div>
            {album?.albumId &&
                <div id="photo-list">
                    <div className="photo photo--new">
                        <div className="cover-photo">
                            <DragAndDrop onDrop={handleDrop} onClick={(e) => fileInputRef.current.click(e)}>
                                {!isUploading && <AddAPhoto className="no-photos" color="primary"/>}
                                <Loader loading={isUploading}/>
                            </DragAndDrop>
                            <input type="file" id="photosUpload" ref={fileInputRef} multiple accept="image/*"
                                   onChange={(e) => handleFiles(e.target.files)}></input>
                            {uploadApiError && <div className="upload-error">{uploadApiError}</div>}
                            {uploadSuccess && <div className="upload-success">Photo's uploaded successfully</div>}
                        </div>
                    </div>
                    {photosToUpdate?.map((photo, idx) =>
                        <div className="card-layout photo" key={photo.photoId}>
                            <div className="cover-photo">
                                {<img src={`${photo.url}?width=450&height=450&fit=cover`} alt={photo.name}/>}
                                <div className="file-name">
                                    {photo.fileName}
                                </div>
                            </div>
                            <div className="card-details">
                                <div className="title input-element">
                                    <TextField fullWidth={true} variant="standard"
                                               value={photo.name}
                                               onChange={(e) => handlePhotoNameChange(idx, e)}
                                    />
                                </div>
                                <div className="description input-element">
                                    <TextField multiline={true} fullWidth={true} rows={8}
                                               onChange={(e) => handlePhotoDescriptionChange(idx, e)}
                                               value={photo.description || ""}
                                    />
                                </div>
                                <div className="input-buttons">
                                    <div className="left-buttons">
                                        <Button className="photo-save" variant="contained" color="error"
                                                onClick={(e) => handleDeletePhoto(e, photo.photoId)}>
                                            Delete
                                        </Button>
                                        <Button className="photo-save" variant="contained" color="info"
                                                disabled={!photoHasChanges(idx)}
                                                onClick={() => handleSavePhotoEdit(idx)}>
                                            Save
                                        </Button>
                                    </div>
                                    <div className="right-buttons">
                                        <Button className="photo-save"
                                                style={{visibility: (idx > 0 ? "visible" : "hidden")}}
                                                startIcon={<MoveUp/>} variant="contained" color="action"
                                                onClick={() => handleMovePhotoUp(idx)}>
                                            Move up
                                        </Button>
                                        <Button className="photo-save"
                                                style={{visibility: (idx < photosToUpdate.length - 1 ? "visible" : "hidden")}}
                                                startIcon={<MoveDown/>} variant="contained" color="action"
                                                onClick={(e) => handleMovePhotoDown(idx)}>
                                            Move down
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            }
        </div>}
    </div>;
}

export default AlbumPage;