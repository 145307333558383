import {useState} from "react";
import "./drag-and-drop.scss";
import Loader from "../Loader";
import * as React from "react";

function DragAndDrop({className, onDrop, children, isLoading, ...otherAtributes}) {
    const [dragging, setDragging] = useState(false);

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    }
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    }

    const handleDragStart = (e) => {
        e.dataTransfer.clearData();
    }
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            onDrop(e.dataTransfer.files)
        }
    }

    return (
        <div className={`draggable ${dragging ? "draggable--dragging" : ""} ${className || ""}`}
             onDragEnter={handleDragEnter} onDragStart={handleDragStart} {...otherAtributes}>
            {dragging && <div className="draggable-overlay" onDragLeave={handleDragLeave} onDrop={handleDrop} /> }
            <div className="draggable-content">
                {children}
            </div>
            <Loader loading={isLoading}/>
        </div>
    );
}

export default DragAndDrop;
