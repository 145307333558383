import {useOutsideAlerter} from "../hooks/outside-click-alerter";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {useRef, useState} from "react";
import {Person} from '@mui/icons-material';
import {Logo} from "./logo";
import {Button, IconButton} from "@mui/material";

import "./header.scss";
import {useAuth} from "../services/authentication/use-auth";

export default function Header() {
    const navigate = useNavigate();
    const {userData, userManager} = useAuth();
    const [showUserDropout, setShowUserDropout] = useState(false);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => {
        setShowUserDropout(false);
    });

    const handleSignOut = async () => {
        await userManager.signoutRedirect();
        navigate("/");
    }

    const handleUserIconClicked = () => {
        setShowUserDropout(!showUserDropout);
    }

    return <header id="admin-header" ref={wrapperRef}>
        <div id="logo" onClick={() => navigate("/")}><Logo/></div>
        {userData &&
            <div id="user-icon">
                <IconButton aria-label="person" onClick={handleUserIconClicked}>
                    <Person/>
                </IconButton>
                {showUserDropout && userData && <div id="user-icon-dropout">
                    <div>{userData.profile?.email}</div>
                    {userData ? <div><Button variant="contained" onClick={handleSignOut}>Sign out</Button></div> : null}
                </div>}
            </div>}
    </header>;
}