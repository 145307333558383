import {useMatches} from "react-router-dom";
import "./breadcrumb.scss";

export function Breadcrumb() {
    let matches = useMatches();
    let crumbs = matches
        // first get rid of any matches that don't have handle and crumb
        .filter((match) => Boolean(match.handle?.crumb))
        // now map them into an array of elements, passing the loader
        // data to each one
        .map((match) => match.handle.crumb(match));

    return <>
        <div id="breadcrumb">
            {crumbs.map((crumb, index) => <span key={index}>
                <span className="crumb">{crumb}</span>
                {index > 0 && index < crumbs.length - 1
                    ? <span className="separator">/</span>
                    : <></>}
            </span>)}
        </div>
    </>;
}