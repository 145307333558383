import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {Button, IconButton, InputAdornment, TextField} from "@mui/material";
import {useAuth} from "../services/authentication/use-auth";
import {useInstances} from "../services/instances/use-instances";
import {Instance} from "../models/instance";
import {
    AddAPhoto, AddCircle, ArrowRightAltOutlined, Edit, RadioButtonChecked, RadioButtonUnchecked, RemoveCircle
} from "@mui/icons-material";

import "./instances-page.scss";
import DragAndDrop from "../components/drag-and-drop/drag-and-drop";

export function InstancesPage() {
    const navigate = useNavigate();
    const {
        instances, createInstance, deleteInstance, updateInstance, uploadCoverPhoto, instanceApiError
    } = useInstances();
    const [instancesToUpdate, setInstancesToUpdate] = useState(null);
    const {userData} = useAuth();

    const [newInstanceTitle, setNewInstanceTitle] = useState("");

    useEffect(() => {
        setInstancesToUpdate(instances?.map(inst => new Instance(inst)) || []);
    }, [instances]);

    let onNewInstanceTitleChanged = (e) => {
        setNewInstanceTitle(e.target.value);
    }

    const handleInstanceTitleChange = (e, idx) => {
        instancesToUpdate[idx].title = e.target.value;
        setInstancesToUpdate([...instancesToUpdate]);
    }
    const handleInstanceSubtitleChange = (e, idx) => {
        instancesToUpdate[idx].subtitle = e.target.value;
        setInstancesToUpdate([...instancesToUpdate]);
    }
    const handleNewInstanceUrlChange = (e, idx) => {
        instancesToUpdate[idx].newInstanceUrl = e.target.value;
        setInstancesToUpdate([...instancesToUpdate]);
    }
    const handleAddNewInstanceUrl = (e, idx) => {
        instancesToUpdate[idx].urls.push(instancesToUpdate[idx].newInstanceUrl);
        instancesToUpdate[idx].newInstanceUrl = "";
        setInstancesToUpdate([...instancesToUpdate]);
    }
    const handleDeleteUrl = (e, idx, url) => {
        instancesToUpdate[idx].urls = instancesToUpdate[idx].urls.filter(u => u !== url);
        setInstancesToUpdate([...instancesToUpdate]);
    }

    const instanceHasChanges = (idx) => {
        return (instancesToUpdate[idx].title || "") !== (instances[idx]?.title || "")
            || (instancesToUpdate[idx].subtitle || "") !== (instances[idx]?.subtitle || "")
            || JSON.stringify(instancesToUpdate[idx].urls) !== JSON.stringify(instances[idx]?.urls)
            || instancesToUpdate[idx].homeLayout !== instances[idx]?.homeLayout;
    }
    const handleCreateInstance = async () => {
        await createInstance(new Instance({
            title: newInstanceTitle
        }));
        setNewInstanceTitle("");
    }
    const handleSaveInstance = async (idx) => {
        if (instanceHasChanges(idx)) {
            instancesToUpdate[idx] = await updateInstance(instancesToUpdate[idx]);
            setInstancesToUpdate([...instancesToUpdate]);
        }
    }
    const handleDeleteInstance = async (e, instanceId) => {
        await deleteInstance(instanceId);
    }

    const [isUploading, setIsUploading] = useState({});
    const fileInputRefs = useRef([]);
    const handleFiles = async (event, idx) => {
        setIsUploading({...isUploading, [idx]: true});
        const formData = new FormData();
        formData.append('cover-photo', event.target.files[0]);
        await uploadCoverPhoto(instancesToUpdate[idx].instanceId, formData);
        setIsUploading({...isUploading, [idx]: false});
    }

    const handleDrop = async (e, idx) => {
        await handleFiles(e, idx)
    }
    const handleSelectLayout = (idx, layout) => {
        instancesToUpdate[idx].homeLayout = layout;
        setInstancesToUpdate([...instancesToUpdate]);
    }
    const homeLayouts = [{
        id: 1,
        name: "Layout 1"
    }, {
        id: 2,
        name: "Layout 2"
    }, {
        id: 3,
        name: "Layout 3"
    }]
    return <div id="instance-selector-page">
        <div id="page-title">
            <div id="left-part">
                <div className="title">Sites</div>
            </div>
        </div>
        {instanceApiError && <div className="error-box">{instanceApiError}</div>}
        <div>
            {userData?.profile?.resource_access?.photos?.roles?.indexOf("admin") >= 0 && <>
                <div id="edit-title" className="input-element">
                    <TextField fullWidth={true} variant="standard"
                               value={newInstanceTitle}
                               placeholder="Instance..."
                               onChange={onNewInstanceTitleChanged}
                    />
                </div>
                <div className="input-buttons">
                    <div className="right-buttons">
                        <Button className="save" variant="contained" color="info"
                                onClick={handleCreateInstance}>
                            Create instance
                        </Button>
                    </div>
                </div>
            </>}
        </div>
        <div id="instance-list">
            {instancesToUpdate?.map((instance, idx) => <div
                className="pad flex flex--stretch flex--align-start border border--round instance"
                key={instance.instanceId}>
                <div
                    className={`${idx} cover-photo ${(!isUploading[idx] && instance.coverPhoto ? "" : "cover-photo--no-img")}`}>
                    <DragAndDrop onDrop={(e) => handleDrop(e, idx)}
                                 onClick={(e) => fileInputRefs.current[idx].click(e)}
                                 isLoading={isUploading[idx]}>
                        {!isUploading[idx] && instance.coverPhoto ? <>
                            <img src={`${instance.coverPhoto.url}?width=450&height=450&fit=cover`}
                                 alt={instance.coverPhoto.name}/>
                            <div className="btn-edit-photo"><Edit/></div>
                        </> : <AddAPhoto className="no-photos"/>}
                    </DragAndDrop>
                    <input type="file" id={`photosUpload`} ref={(element) => fileInputRefs.current[idx] = element}
                           accept="image/*"
                           onChange={(e) => handleFiles(e, idx)}></input>
                </div>
                <div className="card-details">
                    <div className="flex flex--align-start flex--vert-sm flex--align-center-sm">
                        <div className="card-details-left">
                            <div className="title input-element">
                                <TextField fullWidth={true} variant="standard"
                                           value={instance.title}
                                           placeholder="Title..."
                                           onChange={(e) => handleInstanceTitleChange(e, idx)}
                                />
                            </div>
                            <div className="description input-element">
                                <TextField fullWidth={true} variant="standard"
                                           value={instance.subtitle || ""}
                                           placeholder="Subtitle..."
                                           onChange={(e) => handleInstanceSubtitleChange(e, idx)}
                                />
                            </div>
                            <div className="url input-element">
                                <TextField fullWidth={true} variant="standard"
                                           value={instance.newInstanceUrl || ""}
                                           placeholder="https://my-photos.be"
                                           onChange={(e) => handleNewInstanceUrlChange(e, idx)}
                                           InputProps={{
                                               endAdornment: (<InputAdornment position="end">
                                                   <IconButton color="warning"
                                                               onClick={(e) => handleAddNewInstanceUrl(e, idx)}>
                                                       <AddCircle/>
                                                   </IconButton>
                                               </InputAdornment>)
                                           }}/>
                                <div className="instance-links">
                                    {instance.urls.map(url => <div className="instance-link" key={url}>
                                        <IconButton color="error" onClick={(e) => handleDeleteUrl(e, idx, url)}>
                                            <RemoveCircle/>
                                        </IconButton>
                                        <NavLink to={url}>{url}</NavLink>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                        <div className="card-details-right">
                            <div className="flex flex--vert flex--align-start pad">
                                <span className="inline-block bold">Home layout:</span>
                                <div className="flex flex--vert pad">
                                    {homeLayouts.map(layout =>
                                        <div key={layout.id} className="flex pointer" onClick={() => handleSelectLayout(idx, layout.id)}>
                                        <span>{instance.homeLayout === layout.id ? <RadioButtonChecked/> :
                                            <RadioButtonUnchecked/>}
                                        </span>
                                            <span> {layout.name}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="input-buttons">
                        <div className="left-buttons">
                            <Button className="photo-save" variant="contained" color="error"
                                    onClick={(e) => handleDeleteInstance(e, instance.instanceId)}>
                                Delete
                            </Button>
                            <Button className="photo-save" variant="contained" color="info"
                                    disabled={!instanceHasChanges(idx)}
                                    onClick={() => handleSaveInstance(idx)}>
                                Save
                            </Button>
                        </div>
                        <div className="right-buttons">
                            <Button className="photo-save" variant="contained" color="info"
                                    onClick={() => navigate(`/${instance.instanceId}`)}>
                                Manage <ArrowRightAltOutlined/>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>)}
        </div>
    </div>;
}